<template>
  <div></div>
</template>
<script>
import { getAppStatus } from '@/utils/app';
import {
  changeParam,
  base64Encode, userAgentType,
  base64Decode,
} from '@/utils';

export default {
  name: 'PartnerXsw',
  mounted() {
    let { isPhone, isAndroid } = userAgentType();
    isPhone && this.iosData();
    isAndroid && this.androidData();
  },
  computed: {
    appinfo() {
      let appInfo = window?.android?.jzAppInfo() || '{}';
      appInfo = JSON.parse(appInfo);
      return appInfo;
    },
    cuserId() {
      return getAppStatus().cuserId;
    },
  },
  methods: {
    androidData() {
      let userid = this.cuserId;
      const en = window.btoa(userid);
      const {
        deviceId,
        cmodel,
        cphoneos,
        imei,
      } = this.appinfo;
      let timestamp = new Date().getTime();
      timestamp = Math.round(timestamp / 1000);
      const params = {
        device_id: deviceId || '',
        imei1: imei,
        imei2: '',
        mobile_model: cmodel || '',
        mt_id: 1198, // 媒体id，在悬赏蛙媒体主后台获取
        mt_user_id: en,
        oaid: 'f018ef72ef06bc12',
        sys_ver: cphoneos || '',
        timestamp,
        // mt_key: 'ba3e6df69f02a7104f889e720ea21cdd',
      };
      let sign = `${params.device_id}#${params.imei1}#${params.imei2}#${params.mobile_model}#1198#${params.mt_user_id}#${params.oaid}#${params.sys_ver}#${params.timestamp}#ba3e6df69f02a7104f889e720ea21cdd`;
      sign = window?.hex_md5(base64Encode(sign));
      params.sign = sign;
      window.location.href = `https://appunion.xuanshangwa.com.cn/task${changeParam(params)}`;
    },
    iosData() {
      let appInfo = window?.ios?.jzAppInfo() || '{}';
      appInfo = JSON.parse(appInfo);
      let userid = this.cuserId;
      const en = window.btoa(userid);
      let { cimei, cmodel, cphoneos } = appInfo;
      let timestamp = new Date().getTime();
      timestamp = Math.round(timestamp / 1000);
      const params = {
        mt_id: 1214,
        mt_user_id: en,
        idfa: cimei,
        mobile_model: cmodel,
        sys_ver: cphoneos,
        timestamp,
        // mt_key: '01627178577c2ab13392e77eb0373abc',
      };
      let sign = `${cimei}#${cmodel}#1214#${en}#${cphoneos}#${timestamp}#01627178577c2ab13392e77eb0373abc`;
      console.log(sign);
      sign = window?.hex_md5(base64Encode(sign));
      params.sign = sign;
      window.location.href = `https://appunion.xuanshangwa.com.cn/task${changeParam(params)}`;
      // window.location.replace(`https://appunion.xuanshangwa.com.cn/task${changeParam(params)}`);
    },
  },
};
</script>
